import React from "react";
import MenuItem from "./MenuItem";
import { connect } from "react-redux";

const MenuComponent = props => {
  return (
      <div className="menu-component">
        <h2 className="content-header">Меню</h2>
        <table className="table table-hover menu-table">
          <thead>
            <tr>
              <th className="table-header">Наименование</th>
              <th className="table-header">Вес</th>
              <th className="table-header table-header__1-xe">1&nbsp;ХЕ</th>
              <th className="table-header">ХЕ</th>
              <th className="table-header" />
            </tr>
          </thead>
          <tbody>
            {props.currentMenu.map(item => (
              <MenuItem
                key={item.id}
                id={item.id}
                name={item.itemName}
                weight={item.itemWeight}
                xe={item.itemXe}
                xev={item.itemXeV}
                itemXeTotal={item.itemXeTotal}
                removeMenuItem={props.removeMenuItem}
              />
            ))}
          </tbody>
        </table>
      </div>
  );
};

const mapStateToProps = state => ({
  currentMenu: state.currentMenu
});

export default connect(mapStateToProps, null)(MenuComponent);
