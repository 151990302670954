import React, { Component } from 'react'
import { connect } from 'react-redux'
import { auth, googleAuthProvider } from '../firebase'

import {
  setCurrentUser,
  setUsersMenu,
  setSensitivity,
  setMorningSensitivity,
  setDaySensitivity,
  setEveningSensitivity,
  getUserStats
} from '../actionCreators'

import { database } from '../firebase'
import map from 'lodash/map'

class SignIn extends Component {
  constructor(props) {
    super()
    this.logOut = this.logOut.bind(this)
    this.logIn = this.logIn.bind(this)
    this.getUserSettings = this.getUserSettings.bind(this)
  }

  componentDidMount() {
    auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        this.props._setCurrentUser(currentUser)
      }
      if (this.props.currentUser) {
        this.usersMenuRef = database.ref(
          `${this.props.currentUser.uid}/custom-menu`
        )
        this.usersMenuRef.on('value', snapshot => {
          const usersMenu = []
          map(snapshot.val(), (item, key) => {
            item.id = key
            usersMenu.push(item)
            this.props._setUsersMenu(usersMenu)
          })
        })
      }
    })
  }

  componentDidUpdate() {
    this.getUserSettings()
  }

  logIn() {
    auth
      .signInWithPopup(googleAuthProvider)
      .then(
        currentUser => {
          this.props._setCurrentUser(currentUser)
        },
        error => {
          console.log(error)
        }
      )
      .then(() => {
        this.getUserSettings()
      })
  }
  logOut() {
    auth.signOut().then(
      () => {
        this.props._setCurrentUser(null)
      },
      error => {
        console.log(error)
      }
    )
  }
  getUserSettings() {
    const statsArr = []
    if (this.props.currentUser) {
      this.userSettingsRef = database.ref(
        `${this.props.currentUser.uid}/user-settings`
      )
      this.userSettingsRef.on('value', snapshot => {
        if (snapshot && snapshot.val()) {
          this.props._setUserSettings(snapshot.val())
        }
      })
      this.userStatsRef = database.ref(
        `${this.props.currentUser.uid}/saved-results`
      )
      this.userStatsRef.on('value', snapshot => {
        const statsData = snapshot.val()
        for (var key in statsData) {
          statsArr.push(statsData[key])
        }
        this.props._getUserStats(statsArr)
        return
      })
    }
  }

  render() {
    return (
      <div className="btn-group btn-group-justified">
        {this.props.currentUser ? (
          <button
            className="btn btn-primary btn__sign-in"
            onClick={this.logOut}
          >
            <div className="nav-item">
              <img
                className="user-photo"
                alt="sign in"
                src={this.props.currentUser.photoURL}
              />
              <span className="nav-item__link-text">Выйти</span>
            </div>
          </button>
        ) : (
          <button className="btn btn-primary btn__sign-in" onClick={this.logIn}>
            <div className="nav-item">
              <img alt="sign in" src="assets/icons/login.svg" />
              <span className="nav-item__link-text">Войти</span>
            </div>
          </button>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  _setCurrentUser(currentUser) {
    dispatch(setCurrentUser(currentUser))
  },
  _setUsersMenu(usersMenu) {
    dispatch(setUsersMenu(usersMenu))
  },
  _setUserSettings(userSettings) {
    dispatch(setMorningSensitivity(userSettings.morningSensitivity))
    dispatch(setDaySensitivity(userSettings.daySensitivity))
    dispatch(setEveningSensitivity(userSettings.eveningSensitivity))
    dispatch(setSensitivity(userSettings.sensitivity))
  },
  _getUserStats(statsData) {
    dispatch(getUserStats(statsData))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)
