import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyCtyCc4Jvh1sv_Z3Y5YrqrBGe_9fZZGRQs",
  authDomain: "insulin-calc.firebaseapp.com",
  databaseURL: "https://insulin-calc.firebaseio.com",
  projectId: "insulin-calc",
  storageBucket: "insulin-calc.appspot.com",
  messagingSenderId: "599017041680"
};

firebase.initializeApp(config);

export default firebase;

export const database = firebase.database();
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
