import moment from 'moment'

export function filterByCurrentMounth(momentDateObject) {
  const today = moment()
  return momentDateObject.isSame(today, 'month')
}

export function filterByCurrentYear(momentDateObject) {
  const today = moment()
  return momentDateObject.isSame(today, 'year')
}

export function filterByCurrentWeek(momentDateObject) {
  const today = moment()
  return momentDateObject.isSame(today, 'week')
}

export function DataObject(params) {
  const { currentSugar, date, insulin, targetSugar, totalXe, menu } = params
  this.date = moment(date)
  this.currentSugar = parseFloat(currentSugar, 10)
  this.insulin = parseFloat(insulin, 10)
  this.targetSugar = parseFloat(targetSugar, 10)
  this.totalXe = parseFloat(totalXe, 10)
  this.menu = menu
}

export function formatDate(date) {
  moment.locale('ru')
  return moment(date).format('D.MMM.YY HH:mm')
}

export function filterByDays(amount, momentDateObject) {
  const today = moment()
  const sevenDaysBefore = moment().subtract(amount, 'days')
  return momentDateObject.isBetween(sevenDaysBefore, today, 'day', '[]')
}

export function prepareDataToChart(data) {
  return data
    .map((d) => {
      const date = new Date(d.date)
      const currentSugar = parseFloat(d.currentSugar, 10)
      const insulin = parseFloat(d.insulin, 10)
      const targetSugar = parseFloat(d.targetSugar, 10)
      const totalXe = parseFloat(d.totalXe, 10)
      return {
        currentSugar,
        date,
        insulin,
        targetSugar,
        totalXe,
      }
    })
    .sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
}
