import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { setTotalXe, getUserStats } from '../actionCreators'
import { database } from '../firebase'

class Results extends Component {
  constructor() {
    super()
    this.state = {
      resultsSaved: false,
      totalXe: 0
    }
    this.saveResult = this.saveResult.bind(this)
  }
  componentWillMount() {
    const currentMenu = this.props.currentMenu
    let total = 0
    currentMenu.forEach(item => (total += item.itemXeTotal))
    this.props._setTotalXe(total)
    this.setState({ totalXe: total })
  }
  saveResult() {
    const { currentMenu } = this.props
    const date = moment().format('M/D/YYYY, h:mm:ss A')
    const insulin = parseFloat(
      (this.props.currentSugar - this.props.targetSugar) /
        this.props.sensitivity +
        this.props.totalXe * this.props.coefficient
    ).toFixed(2)
    if (this.props.currentUser) {
      const statsArr = []
      this.userSettingsRef = database.ref(
        `${this.props.currentUser.uid}/saved-results`
      )
      this.userSettingsRef.push({
        currentSugar: this.props.currentSugar,
        date,
        targetSugar: this.props.targetSugar,
        totalXe: this.state.totalXe,
        insulin,
        menu: currentMenu,
      })
      this.userSettingsRef.on('value', snapshot => {
        const statsData = snapshot.val()
        for (var key in statsData) {
          statsArr.push(statsData[key])
        }
        this.props._getUserStats(statsArr)
      })
    }
    this.setState({ resultsSaved: true })
  }
  componentDidMount() {
    console.log(this.props.currentMenu)
  }

  render() {
    const {
      currentSugar,
      targetSugar,
      sensitivity,
      exposition,
      totalXe,
      coefficient,
      injectionPlace,
      currentUser
    } = this.props
    const { resultsSaved } = this.state
    const correction = parseFloat(
      (currentSugar - targetSugar) / sensitivity
    ).toFixed(2)
    const insulin = parseFloat(
      (currentSugar - targetSugar) / sensitivity + totalXe * coefficient
    ).toFixed(2)
    const insulinBolus = parseFloat(totalXe * coefficient).toFixed(2)
    const totalXeFormatted = parseFloat(totalXe).toFixed(2)
    return (
      <div>
        {currentSugar && targetSugar ? (
          <div>
            <h2 className='content-header'>Результаты расчетов</h2>
            <table className='results-table'>
              <tbody>
                <tr>
                  <td className='table-header results-cell'>
                    Сахар в крови перед едой
                  </td>
                  <td className='table-header results-cell'>{currentSugar}</td>
                </tr>
                <tr>
                  <td className='table-header results-cell'>
                    Ожидаемый уровень сахара
                  </td>
                  <td className='table-header results-cell'>{targetSugar}</td>
                </tr>
                <tr>
                  <td className='table-header results-cell'>Коэффициент</td>
                  <td className='table-header results-cell'>{coefficient}</td>
                </tr>
                <tr>
                  <td className='table-header results-cell'>Всего ХЕ</td>
                  <td className='table-header results-cell'>
                    {totalXeFormatted}
                  </td>
                </tr>
                <tr>
                  <td className='table-header results-cell'>Болюс инсулина</td>
                  <td className='table-header results-cell'>{insulinBolus}</td>
                </tr>
                <tr>
                  <td className='table-header results-cell'>Экспозиция</td>
                  <td className='table-header results-cell'>{exposition}</td>
                </tr>
                <tr>
                  <td className='table-header results-cell'>Место инъекции</td>
                  <td className='table-header results-cell'>
                    {injectionPlace}
                  </td>
                </tr>
                <tr>
                  <td className='table-header results-cell'>
                    Корректировка болюса
                  </td>
                  <td className='table-header results-cell'>{correction}</td>
                </tr>
                <tr>
                  <td className='table-header results-cell'>
                    Расчетная доза инсулина
                  </td>
                  <td className='table-cell results-table__table-cell'>
                    <b>{insulin}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='add-menu-btns'>
              {!resultsSaved && currentSugar && targetSugar && currentUser && (
                <button
                  onClick={this.saveResult}
                  className='btn main-btn add-menu-btn'
                >
                  Сохранить
                </button>
              )}
              <Link to='/' className='btn main-btn add-menu-btn'>
                Пересчитать
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <h2 className='content-header'>Нет данных</h2>
            <div className='add-menu-btns'>
              <Link to='/' className='btn main-btn add-menu-btn'>
                Ввести
              </Link>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  coefficient: state.coefficient,
  currentMenu: state.currentMenu,
  currentSugar: state.currentSugar,
  currentUser: state.currentUser,
  daySensitivity: state.daySensitivity,
  eveningSensitivity: state.eveningSensitivity,
  exposition: state.exposition,
  injectionPlace: state.injectionPlace,
  morningSensitivity: state.morningSensitivity,
  sensitivity: state.sensitivity,
  targetSugar: state.targetSugar,
  time: state.time,
  totalXe: state.totalXe
})

const mapDispatchToProps = dispatch => ({
  _setTotalXe(value) {
    dispatch(setTotalXe(value))
  },
  _getUserStats(statsData) {
    dispatch(getUserStats(statsData))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Results)
