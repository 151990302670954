import React, { Component } from 'react'
import * as d3 from 'd3'
import { prepareDataToChart } from '../utils/utils'

class StatsChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 600,
      height: 400,
      insulinBars: [],
      currentSugarLine: '',
      targetSugarLine: '',
      xScale: [],
      yScale: [],
      margin: { top: 20, bottom: 90, left: 30, right: 20 },
      targetBar: null,
    }
    this.chartContainer = React.createRef()
    this.chart = React.createRef()
  }

  setChartWidth = (value) => {
    this.setState({
      width: value,
    })
  }

  xAxis = d3.axisBottom()
  yAxis = d3.axisLeft()

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    const { width, height, margin } = this.state
    const mData = prepareDataToChart(data)

    const xExtent = d3.extent(mData, (d) => d.date)
    const maxCurrentSugar = d3.max(mData, (d) => d.currentSugar)
    const maxInsulin = d3.max(mData, (d) => d.insulin)
    const maxTargetSugar = d3.max(mData, (d) => d.targetSugar)
    const maxTotalXe = d3.max(mData, (d) => d.totalXe)
    const maxValues = [maxCurrentSugar, maxInsulin, maxTargetSugar, maxTotalXe]

    const yMax = d3.max(maxValues, (d) => d)

    const xScale = d3
      .scaleTime()
      .domain(xExtent)
      .range([margin.left, width - margin.right])

    const yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([height - margin.bottom, margin.top])

    const heightScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([0, height - margin.top - margin.bottom])

    const currentSugar = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.currentSugar))
      .curve(d3.curveCatmullRom)

    const targetSugar = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.targetSugar))

    const insulinBars = mData.map((d) => ({
      height: heightScale(d.insulin),
      x: xScale(d.date),
      y: yScale(d.insulin),
    }))

    const targetBar = {
      height: heightScale(5) || 0,
      width: width - margin.left - margin.right,
      x: xScale(xExtent[0]) || 0,
      y: yScale(10) || 0,
    }

    const currentSugarLine = currentSugar(mData)
    const targetSugarLine = targetSugar(mData)

    this.setState({
      insulinBars,
      currentSugarLine,
      targetSugarLine,
      yScale,
      xScale,
      targetBar,
    })
  }

  componentDidMount() {
    const chartWidth = this.chartContainer.current.offsetWidth
    this.setChartWidth(chartWidth)
  }

  componentDidUpdate() {
    const { xScale, yScale } = this.state
    this.xAxis.scale(xScale)
    this.yAxis.scale(yScale)
    d3.select(this.refs.xAxis)
      .call(this.xAxis.tickFormat(d3.timeFormat('%Y.%m.%d %I:%M')))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')
    d3.select(this.refs.yAxis).call(this.yAxis)
  }

  render() {
    const {
      width,
      height,
      insulinBars,
      currentSugarLine,
      targetSugarLine,
      targetBar,
      margin,
    } = this.state
    return (
      <div className='StatsChart' ref={this.chartContainer}>
        <svg
          ref={this.chart}
          className='StatsChartSvg'
          width={width}
          height={height}
        >
          {targetBar !== null && (
            <rect
              key='someKey'
              width={targetBar.width}
              height={targetBar.height}
              x={margin.left}
              y={targetBar.y}
              fill='#4B6A6F'
              stroke='#8a9d93'
            ></rect>
          )}
          
          {insulinBars.map((bar) => (
            <rect
              key={`${bar.x}-${bar.y}`}
              width='3'
              height={bar.height}
              x={bar.x}
              y={bar.y}
              fill='#8a9d93'
              stroke='#8a9d93'
            ></rect>
          ))}

          {false && (
            <path
              d={targetSugarLine}
              stroke='#aede7c'
              strokeWidth='2'
              fill='none'
            />
          )}

          <path
            d={currentSugarLine}
            stroke='#FF649B'
            strokeWidth='2'
            fill='none'
          />
          <g
            ref='xAxis'
            className='axisGreen'
            transform={`translate(0, ${height - margin.bottom})`}
          />
          <g
            ref='yAxis'
            className='axisGreen'
            transform={`translate(${margin.left}, 0)`}
          />
        </svg>
      </div>
    )
  }
}

export default StatsChart
