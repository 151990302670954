import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import StatsChart from "./StatsChart";

import {
  filterByCurrentYear,
  filterByCurrentMounth,
  filterByCurrentWeek,
  filterByDays,
  DataObject,
  formatDate,
} from "../utils/utils";

const StatisticsItem = (props) => {
  const [isMenuOpen, toggleMenu] = useState(false);
  const { currentSugar, date, insulin, targetSugar, totalXe, menu } = props;
  const isMenuAvailable = menu && menu.length > 0;
  const localDate = formatDate(date);
  return (
    <div
      className="statsRow"
      onClick={
        isMenuAvailable
          ? () => toggleMenu(!isMenuOpen)
          : () => console.log("no menu")
      }
    >
      <div className="statsRowItem">{localDate}</div>
      <div className="statsRowItem">{currentSugar}</div>
      <div className="statsRowItem">{targetSugar}</div>
      <div className="statsRowItem">{parseFloat(totalXe).toFixed(2)}</div>
      <div className="statsRowItem">{parseFloat(insulin).toFixed(2)}</div>
      {isMenuAvailable && (
          <div className="hasMenu">
            M
          </div>
      )}
      {isMenuOpen && isMenuAvailable && (
        <table className="menuItems">
          <tr>
            <th>Наименование</th>
            <th>Вес</th>
            <th>ХЕ</th>
          </tr>
          {menu.map((menuItem) => {
            const { itemName, itemWeight, itemXeTotal } = menuItem;
            return (
              <tr className="menuItem">
                <td>{itemName}</td>
                <td>{itemWeight}</td>
                <td>{itemXeTotal}</td>
              </tr>
            );
          })}
        </table>
      )}
    </div>
  );
};

const Statistics = ({ userStats }) => {
  const [dateFilter, setDateFilter] = useState("week");
  // const [currentView, toggleView] = useState('table')
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filteredUserStats = () => {
      const patchedData = userStats.map(
        (dataObject) => new DataObject(dataObject)
      );
      let filterResult = [];
      switch (dateFilter) {
        case "year":
          filterResult = patchedData.filter((dataObject) =>
            filterByCurrentYear(dataObject.date)
          );
          setFilteredData(filterResult);
          break;
        case "week":
          filterResult = patchedData.filter((dataObject) =>
            filterByCurrentWeek(dataObject.date)
          );
          setFilteredData(filterResult);
          break;
        case "month":
          filterResult = patchedData.filter((dataObject) =>
            filterByCurrentMounth(dataObject.date)
          );
          setFilteredData(filterResult);
          break;
        case "7days":
          filterResult = patchedData.filter((dataObject) =>
            filterByDays(7, dataObject.date)
          );
          setFilteredData(filterResult);
          break;
        case "30days":
          filterResult = patchedData.filter((dataObject) =>
            filterByDays(30, dataObject.date)
          );
          setFilteredData(filterResult);
          break;
        case "90days":
          filterResult = patchedData.filter((dataObject) =>
            filterByDays(90, dataObject.date)
          );
          setFilteredData(filterResult);
          break;
        case "183days":
          filterResult = patchedData.filter((dataObject) =>
            filterByDays(183, dataObject.date)
          );
          setFilteredData(filterResult);
          break;
        case "365days":
          filterResult = patchedData.filter((dataObject) =>
            filterByDays(365, dataObject.date)
          );
          setFilteredData(filterResult);
          break;
        case "":
          setFilteredData(patchedData);
          break;
        default:
          setFilteredData(patchedData);
      }
    };
    filteredUserStats();
  }, [userStats, dateFilter]);

  return (
    <div className="Statistics">
      {/* <select
        className='main-input main-input--sugar-value form-control'
        onChange={(e) => toggleView(e.target.value)}
        defaultValue='table'
      >
        <option value='table'>Таблица</option>
        <option value='chart'>График</option>
      </select> */}
      <select
        className="main-input main-input--sugar-value form-control"
        onChange={(e) => setDateFilter(e.target.value)}
        defaultValue="week"
      >
        <option value="week">Текущая неделя</option>
        <option value="7days">7 дней</option>
        <option value="month">Текущий месяц</option>
        <option value="year">Текущий год</option>
        <option value="30days">30 дней</option>
        <option value="90days">3 месяца</option>
        <option value="183days">6 месяцев</option>
        <option value="365days">год</option>
      </select>
      {/* {currentView === 'table' && ( */}
      <div className="statsTable">
        <div className="statsTableHead">Дата</div>
        <div className="statsTableHead">Нач. сахар</div>
        <div className="statsTableHead">Целевой сахар</div>
        <div className="statsTableHead">Всего ХЕ</div>
        <div className="statsTableHead">Инсулин</div>
      </div>
      {userStats &&
        filteredData &&
        filteredData.map((item, i) => <StatisticsItem key={i} {...item} />)}
      {/* )} */}
      {/* {currentView === 'chart' && ( */}
      <>{filteredData && <StatsChart data={filteredData} />}</>
      {/* )} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userStats: state.userStats,
});

export default connect(mapStateToProps)(Statistics);
