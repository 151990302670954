import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  setTargetSugar,
  setCurrentSugar,
  setTime,
  setCoefficient,
  setExposition,
  setInjectionPlace
} from "../actionCreators";

import { database } from "../firebase";

class SugarValues extends Component {
  constructor() {
    super();
    this.state = {
      newData: "",
      currentUser: null
    };
    this.dataRef = null;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    const date = new Date();
    const time = date.getHours();
    this.props._setTime(time);
    if (time < 4) {
      this.props._setCoefficient(this.props.eveningSensitivity);
      this.props._setExposition(0);
      this.props._setInjectionPlace("В руку");
    } else if (time < 12 && time > 4) {
      this.props._setCoefficient(this.props.morningSensitivity);
      this.props._setExposition(30);
      this.props._setInjectionPlace("В живот");
    } else if (time >= 12 && time < 18) {
      this.props._setCoefficient(this.props.daySensitivity);
      this.props._setExposition(15);
      this.props._setInjectionPlace("В руку");
    } else {
      this.props._setCoefficient(this.props.eveningSensitivity);
      this.props._setExposition(0);
      this.props._setInjectionPlace("В руку");
    }
  }
  handleSubmit(event) {
    this.dataRef = database.ref("/data");
    event.preventDefault();
    this.dataRef.push({
      name: this.state.newData,
      value: "some-value"
    });
  }
  handleChange(event) {
    this.setState({ newData: event.target.value });
  }
  render() {
    return (
      <div className="sugar-values">
        <h2 className="content-header">Показатели сахара в крови</h2>
        <div>
          <div className="form-group">
            <label className="input-label" htmlFor="currentSugar">
              Текущий:
            </label>
            <input
              id="currentSugar"
              className="main-input main-input--sugar-value form-control"
              type="number"
              onChange={this.props._setCurrentSugar}
              value={this.props.currentSugar}
              placeholder="Ввести сахар до еды"
            />
          </div>
          <div className="form-group">
            <label className="input-label" htmlFor="targetShugar">
              Желаемый:
            </label>
            <input
              id="targetShugar"
              className="main-input main-input--sugar-value form-control"
              type="number"
              onChange={this.props._setTargetSugar}
              value={this.props.targetSugar}
              placeholder="Ввести целевой сахар"
            />
          </div>
          <div className="button-container">
            <Link
              disabled={!this.props.currentSugar && !this.props.targetSugar}
              to="/menu"
              className="btn main-btn add-menu-btn"
            >
              Составить меню
            </Link>
            <Link to="/settings" className="btn main-btn add-menu-btn">
              Настройки
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  time: state.time,
  coefficient: state.coefficient,
  exposition: state.exposition,
  injectionPlace: state.injectionPlace,
  currentSugar: state.currentSugar,
  targetSugar: state.targetSugar,
  eveningSensitivity: state.eveningSensitivity,
  morningSensitivity: state.morningSensitivity,
  daySensitivity: state.daySensitivity,
  sensitivity: state.sensitivity
});

const mapDispatchToProps = dispatch => ({
  _setCurrentSugar(event) {
    dispatch(setCurrentSugar(event.target.value));
  },
  _setTargetSugar(event) {
    dispatch(setTargetSugar(event.target.value));
  },
  _setTime(time) {
    dispatch(setTime(time));
  },
  _setCoefficient(value) {
    dispatch(setCoefficient(value));
  },
  _setExposition(value) {
    dispatch(setExposition(value));
  },
  _setInjectionPlace(value) {
    dispatch(setInjectionPlace(value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SugarValues);
