import {
  SET_SENSINSITIVITY,
  SET_MORNING_SENSITIVITY,
  SET_DAY_SENSITIVITY,
  SET_EVENING_SENSITIVITY,
  SET_TARGET_SUGAR,
  SET_CURRENT_SUGAR,
  ADD_MENU_ITEM,
  REMOVE_MENU_ITEM,
  SET_TIME,
  SET_COEFFICIENT,
  SET_EXPOSITION,
  SET_INJECTION_PLACE,
  SET_TOTAL_XE,
  SET_CURRENT_USER,
  SET_USERS_MENU,
  GET_USER_STATISTICS
} from './actions'

export function setTime(value) {
  return {
    type: SET_TIME,
    payload: value
  }
}

export function setCoefficient(value) {
  return {
    type: SET_COEFFICIENT,
    payload: value
  }
}

export function setExposition(value) {
  return {
    type: SET_EXPOSITION,
    payload: value
  }
}

export function setInjectionPlace(value) {
  return {
    type: SET_INJECTION_PLACE,
    payload: value
  }
}

export function setSensitivity(value) {
  return {
    type: SET_SENSINSITIVITY,
    payload: value
  }
}

export function setMorningSensitivity(value) {
  return {
    type: SET_MORNING_SENSITIVITY,
    payload: value
  }
}

export function setDaySensitivity(value) {
  return {
    type: SET_DAY_SENSITIVITY,
    payload: value
  }
}

export function setEveningSensitivity(value) {
  return {
    type: SET_EVENING_SENSITIVITY,
    payload: value
  }
}

export function setTargetSugar(value) {
  return {
    type: SET_TARGET_SUGAR,
    payload: value
  }
}

export function setCurrentSugar(value) {
  return {
    type: SET_CURRENT_SUGAR,
    payload: value
  }
}

export function addMenuItem(item) {
  return {
    type: ADD_MENU_ITEM,
    payload: item
  }
}

export function removeMenuItem(itemId) {
  return {
    type: REMOVE_MENU_ITEM,
    payload: itemId
  }
}

export function setTotalXe(value) {
  return {
    type: SET_TOTAL_XE,
    payload: value
  }
}

export function setCurrentUser(userObject) {
  return {
    type: SET_CURRENT_USER,
    payload: userObject
  }
}

export function setUsersMenu(usersMenu) {
  return {
    type: SET_USERS_MENU,
    payload: usersMenu
  }
}

export function getUserStats(userStats) {
  return {
    type: GET_USER_STATISTICS,
    payload: userStats
  }
}
