import React from "react";

const SelectMenuItem = (props) => {
  if (props.menuItems.length > 0 && props.itemName !== "") {
    return (
      <div className="select-menu-item">
        {props.menuItems.map(menuItem => (
          <li
            className="select-menu-item__item"
            key={props.getId(1000000, 9999999)}
            onClick={() =>
              props.selectFromDropdown(menuItem.name, menuItem.xe)}
          >
            {menuItem.name}
          </li>
        ))}
      </div>
    );
  } else {
    return <span className="no-items">no items</span>;
  }
};

export default SelectMenuItem;
