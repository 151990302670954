import React from "react";
import { connect } from "react-redux";
import { removeMenuItem } from "../actionCreators";

const MenuItem = props => {
  return (
    <tr>
      <td className="table-cell">{props.name}</td>
      <td className="table-cell">{props.weight}&nbsp;грамм</td>
      <td className="table-cell table-header__1-xe">{props.xe}&nbsp;грамм</td>
      <td className="table-cell">{props.itemXeTotal}</td>
      <td className="table-cell">
        <button
          onClick={() => props._removeMenuItem(props.id)}
          className="btn close-btn"
         />
      </td>
    </tr>
  );
};

const mapDispatchToProps = dispatch => ({
  _removeMenuItem(value) {
    dispatch(removeMenuItem(value));
  }
});

export default connect(null, mapDispatchToProps)(MenuItem);
