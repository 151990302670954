import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store'
import SugarValues from './components/SugarValues'
import MenuComponent from './components/MenuComponent'
import AddMenuItem from './components/AddMenuItem'
import Results from './components/Results'
import Settings from './components/Settings'
import UsersMenu from './components/UsersMenu'
import Navigation from './components/Navigation'
import Statistics from './components/Statistics'

import './App.css'

class App extends Component {
  constructor() {
    super()
    this.state = {
      data: null
    }
  }
  // <BrowserRouter basename="/demo/insulin"> .nav-item--active
  render() {
    const NotFound = () => (
      <h2 className='content-header'>diCalc.ru – Расчет инсулина</h2>
    )
    return (
      <BrowserRouter>
        <Provider store={store}>
          <div className='App container'>
            <div className='application'>
              <Navigation />
              <div className='content-area'>
                <Switch>
                  <Route
                    path='/'
                    exact
                    render={() => <SugarValues data={this.state.data} />}
                  />
                  <Route path='/settings' component={Settings} />
                  <Route
                    path='/menu'
                    render={() => (
                      <div>
                        <MenuComponent />
                        <AddMenuItem />
                      </div>
                    )}
                  />
                  <Route path='/results' component={Results} />
                  <Route path='/stats' component={Statistics} />
                  <Route path='/users-menu' component={UsersMenu} />
                  <Route component={NotFound} />
                </Switch>
              </div>
            </div>
          </div>
        </Provider>
      </BrowserRouter>
    )
  }
}

export default App
