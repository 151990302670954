export const SET_SENSINSITIVITY = "SET_SENSINSITIVITY";
export const SET_MORNING_SENSITIVITY = "SET_MORNING_SENSITIVITY";
export const SET_DAY_SENSITIVITY = "SET_DAY_SENSITIVITY";
export const SET_EVENING_SENSITIVITY = "SET_EVENING_SENSITIVITY";
export const SET_TARGET_SUGAR = "SET_TARGET_SUGAR";
export const SET_CURRENT_SUGAR = "SET_CURRENT_SUGAR";
export const ADD_MENU_ITEM = "ADD_MENU_ITEM";
export const REMOVE_MENU_ITEM =  "REMOVE_MENU_ITEM";
export const SET_TIME = "SET_TIME";
export const SET_COEFFICIENT = "SET_COEFFICIENT";
export const SET_EXPOSITION = "SET_EXPOSITION";
export const SET_INJECTION_PLACE = "SET_INGECTION_PLACE";
export const SET_TOTAL_XE = "SET_TOTAL_XE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USERS_MENU = "SET_USERS_MENU";
export const GET_USER_STATISTICS = "GET_USER_STATISTICS";
