import React from 'react'
import { NavLink } from 'react-router-dom'
import SignIn from './SignIn'

const Navigation = () => {
  return (
    <nav className='nav'>
      <ul>
        <li>
          <NavLink
            exact
            activeClassName='nav-item--active'
            className='nav-item'
            to='/'
          >
            <img alt='sugar values' src='assets/icons/sugar-blood-level.svg' />
            <span className='nav-item__link-text'>Сахара</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName='nav-item--active'
            className='nav-item'
            to='/menu'
          >
            <img alt='menu' src='assets/icons/breakfast.svg' />
            <span className='nav-item__link-text'>Меню</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName='nav-item--active'
            className='nav-item'
            to='/results'
          >
            <img alt='results' className='nav-item__icon' src='assets/icons/medical-result.svg' />
            <span className='nav-item__link-text'>Результаты</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName='nav-item--active'
            className='nav-item'
            to='/stats'
          >
            <img alt='stats' className='nav-item__icon' src='assets/icons/analysis.svg' />
            <span className='nav-item__link-text'>Статистика</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName='nav-item--active'
            className='nav-item'
            to='/settings'
          >
            <img alt='settings' src='assets/icons/controls.svg' />
            <span className='nav-item__link-text'>Настройки</span>
          </NavLink>
        </li>
        <li>
          <SignIn />
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
