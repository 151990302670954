export const xeTable = [
  { name: "Абрикос", xe: 140 },
  { name: "Актимель", xe: 100 },
  { name: "Апельсин", xe: 145 },
  { name: "Арахис", xe: 89.55 },
  { name: "Арбуз", xe: 270 },
  { name: "Банан", xe: 70 },
  { name: "Брокколи", xe: 625, xeV: 650 },
  { name: "Виноград", xe: 85 },
  { name: "Вишня", xe: 90 },
  { name: "Геркулес", xe: 23 },
  { name: "Горошек", xe: 150 },
  { name: "Гранат", xe: 170 },
  { name: "Грейпфрут", xe: 160 },
  { name: "Грецкий орех", xe: 150 },
  { name: "Груша", xe: 90 },
  { name: "Изюм", xe: 20 },
  { name: "Йогурт", xe: 150 },
  { name: "Кабачок", xe: 300 },
  { name: "Капуста", xe: 330, xeV: 380 },
  { name: "Капуста цветная", xe: 600, xeV: 640 },
  { name: "Картофель", xe: 72 },
  { name: "Картофель фри", xe: 35 },
  { name: "Каша", xe: 50 },
  { name: "Кетчуп", xe: 15 },
  { name: "Кефир", xe: 250 },
  { name: "Кешью", xe: 40 },
  { name: "Киви", xe: 80 },
  { name: "Клубника", xe: 235 },
  { name: "Колбаса", xe: 100 },
  { name: "Крахмал", xe: 15 },
  { name: "Кукуруза", xe: 70 },
  { name: "Курага", xe: 20 },
  { name: "Курица", xe: 100, info: "отсроченная гипергликемия" },
  { name: "Лимон", xe: 270 },
  { name: "Лук", xe: 133 },
  { name: "Майонез", xe: 600 },
  { name: "Макароны", xe: 50 },
  { name: "Малина", xe: 180 },
  { name: "Манго", xe: 90 },
  { name: "Мандарин", xe: 125 },
  { name: "Мёд", xe: 12 },
  { name: "Молоко", xe: 255 },
  { name: "Морковь", xe: 180, xeV: 220 },
  { name: "Мороженое", xe: 65 },
  { name: "Мука", xe: 15 },
  { name: "Мясо", xe: 100 },
  { name: "Нектарин", xe: 120 },
  { name: "Овсянка на молоке", xe: 131.4 },
  { name: "Огурец", xe: 1300 },
  { name: "Огурец солёный", xe: 705.88 },
  { name: "Оливки", xe: 415 },
  { name: "Перец желтый", xe: 200, xeV: 180 },
  { name: "Перец зелёный", xe: 400, xeV: 360 },
  { name: "Персик", xe: 140 },
  { name: "Персик консервированный", xe: 80 },
  { name: "Помидор", xe: 650 },
  { name: "Попкорн", xe: 17 },
  { name: "Пюре (картофельное)", xe: 95 },
  { name: "Рыба", xe: 100 },
  { name: "Сахар", xe: 12 },
  { name: "Свекла", xe: 120 },
  { name: "Слива", xe: 100 },
  { name: "Сметана", xe: 375 },
  { name: "Сметана 20%", xe: 375 },
  { name: "Смородина", xe: 240 },
  { name: "Солянка", xe: 383.37 },
  { name: "Сосиска", xe: 100 },
  { name: "Сыр", xe: 670 },
  { name: "Сырник", xe: 124 },
  { name: "Тесто", xe: 25 },
  { name: "Творог обезжиренный", xe: 670 },
  { name: "Творог 5%", xe: 670 },
  { name: "Творог 9%", xe: 430.11 },
  { name: "Тыква", xe: 160, xeV: 200 },
  { name: "Фасоль", xe: 50 },
  { name: "Фасоль зеленая", xe: 370 },
  { name: "Фри картофель", xe: 35 },
  { name: "Фундук", xe: 90 },
  { name: "Чипсы", xe: 25 },
  { name: "Хлеб", xe: 20 },
  { name: "Хлебец", xe: 21 },
  { name: "Цезарь", xe: 87.9 },
  { name: "Черешня", xe: 100 },
  { name: "Черника", xe: 90 },
  { name: "Чернослив", xe: 20 },
  { name: "Чечевица", xe: 50 },
  { name: "Шоколад", xe: 20 },
  { name: "Яблоко", xe: 90 }
];
/*
export const xeTable2 = [
  {
    name: "молоко(любая жирность)",
    xe: 250
  },
  {
    name: "кефир(любая жирность)",
    xe: 250
  },
  {
    name: "простокваша(любая жирность)",
    xe: 250
  },
  {
    name: "сливки(любая жирность)",
    xe: 250
  },
  {
    name: "творожная масса с изюмом",
    xe: 40
  },
  {
    name: "творожная сладкая масса",
    xe: 100
  },
  {
    name: "мороженное",
    xe: 65
  },
  {
    name: "Хлеб пшеничный для тостов",
    xe: 20
  },
  {
    name: "Крекеры (сухое печенье, сушки)",
    xe: 15
  },
  {
    name: "Тесто сырое: слоеное",
    xe: 35
  },
  {
    name: "Тесто сырое: дрожжевое",
    xe: 25
  },
  {
    name: "Изделия из печеного теста",
    xe: 50
  },
  {
    name: "Оладьи",
    xe: 50
  },
  {
    name: "Блины",
    xe: 50
  },
  {
    name: "Клецки",
    xe: 15
  },
  {
    name: "Проросшая пшеница",
    xe: 25
  },
  {
    name: "«Попкорн»",
    xe: 15
  },
  {
    name: "Крахмал (картофельный, кукурузный, пшеничный)",
    xe: 15
  },
  {
    name: "пряник",
    xe: 40
  },
  {
    name: "пельмени мороженные",
    xe: 50
  },
  {
    name: "кукуруза",
    xe: 100
  },
  {
    name: "Картофель отварной или печеный",
    xe: 70
  },
  {
    name: "Картофель сухой",
    xe: 25
  },
  {
    name: "Картофельные чипсы",
    xe: 25
  },
  {
    name: "Картофельные оладьи",
    xe: 60
  },
  {
    name: "Макаронные изделия вареные",
    xe: "60"
  },
  {
    name: "Бобы, фасоль, чечевица, соя (спелые зерна)",
    xe: 170
  },
  {
    name: "Кукуруза (початок)",
    xe: 100
  },
  {
    name: "Кукуруза сладкая консервированная",
    xe: 60
  },
  {
    name: "Арахис с кожурой",
    xe: 85
  },
  {
    name: "Грецкие орехи",
    xe: 90
  },
  {
    name: "Кедровые орешки",
    xe: 60
  },
  {
    name: "Лесные орехи",
    xe: 90
  },
  {
    name: "Миндаль",
    xe: 60
  },
  {
    name: "Кешью",
    xe: 40
  },
  {
    name: "Фисташки",
    xe: 60
  },

  {
    name: "Диабетический конфитюр",
    xe: 25
  },
  {
    name: "Сахар-песок",
    xe: 10
  },
  {
    name: "Фруктоза",
    xe: 12
  },
  {
    name: "Сорбит",
    xe: 12
  },
  {
    name: "Абрикос (с косточкой/без косточки)",
    xe: 130
  },
  {
    name: "Айва",
    xe: 140
  },
  {
    name: "Ананас (с кожурой)",
    xe: 90
  },
  {
    name: "Апельсин без кожуры",
    xe: 130
  },
  {
    name: "Банан без кожуры",
    xe: 60
  },
  {
    name: "Гранат",
    xe: 200
  },
  {
    name: "Грейпфрут без кожуры",
    xe: 130
  },
  {
    name: "Груша",
    xe: 90
  },
  {
    name: "Гуава",
    xe: 80
  },
  {
    name: "Дыня (с коркой)",
    xe: 130
  },
  {
    name: "Киви",
    xe: 120
  },
  {
    name: "Манго",
    xe: 90
  },
  {
    name: "Мандарин без кожуры",
    xe: 120
  },
  {
    name: "Папайя",
    xe: 140
  },
  {
    name: "Персик нектарин",
    xe: 100
  },
  {
    name: "Персик",
    xe: 140
  },
  {
    name: "Сливы синие без косточки",
    xe: 110
  },
  {
    name: "Сливы красные",
    xe: 80
  },
  {
    name: "Фейхоа",
    xe: 160
  },
  {
    name: "Черешня (с косточками)",
    xe: 100
  },
  {
    name: "Вишня (с косточками)",
    xe: 110
  },

  {
    name: "Арбуз (с коркой)",
    xe: 250
  },
  {
    name: "Брусника",
    xe: 140
  },
  {
    name: "Бузина",
    xe: 170
  },
  {
    name: "Виноград",
    xe: 70
  },
  {
    name: "Ежевика",
    xe: 170
  },
  {
    name: "Земляника",
    xe: 170
  },
  {
    name: "Клюква",
    xe: 120
  },
  {
    name: "Клубника",
    xe: 200
  },
  {
    name: "Крыжовник",
    xe: 150
  },
  {
    name: "Малина",
    xe: 200
  },
  {
    name: "Смородина красная",
    xe: 200
  },
  {
    name: "Смородина черная",
    xe: 180
  },
  {
    name: "Черника (голубика)",
    xe: 170
  },
  {
    name: "Сухофрукты (изюм, курага, чернослив, инжир, финики)",
    xe: 20
  },
  {
    name: "морковь (средняя)",
    xe: 200
  },
  {
    name: "свёкла (средняя)",
    xe: 150
  },
  {
    name: "тыква",
    xe: 200
  },
  {
    name: "топинамбур",
    xe: 70
  },
  {
    name: " Сосиски, вареная колбаса ",
    xe: 160
  }
];
*/



/*
const xeTable3 = [
  {
    name: "Суп из лапши",
    xe: "3 столовых ложки"
  },
  {
    name: "Соленые палочки",
    xe: "15 палочек"
  },
  {
    name: "Сухари",
    xe: "2 шт."
  },
  {
    name: "Хрустящие хлебцы",
    xe: "2 шт."
  },
  {
    name: "Панировочные сухари",
    xe: "1 столовая ложка"
  },
  {
    name: "Мука тонкого помола",
    xe: "1 столовая ложка"
  },
  {
    name: "Мука грубого помола, цельные зерна пшеницы",
    xe: "2 столовых ложки"
  },
  {
    name: "Мука ржаная",
    xe: "1 столовая ложка"
  },
  {
    name: "Мука соевая цельная, полужирная",
    xe: "4 столовые ложки"
  },
  {
    name: "Крупа любая сырая",
    xe: "1 столовая ложка"
  },
  {
    name: "Каша любая вареная",
    xe: "2 столовых ложки"
  },
  {
    name: "Картофель «в мундире»",
    xe: "1 штука"
  },
  {
    name: "Картофельное пюре(сухой полуфабрикат)",
    xe: "1 столовая ложка"
  },
  {
    name: "Картофельное пюре (на воде)",
    xe: "2 столовых ложки"
  },
  {
    name: "Картофельное пюре (на молоке, масле)",
    xe: "2 столовых ложки"
  },
  {
    name: "Кукурузные и рисовые хлопья (готовые завтраки)",
    xe: "4 столовых ложки"
  },
  {
    name: "«Корнфлекс» (мюсли)",
    xe: "4 столовых ложки"
  },
  {
    name: "Макаронные изделия, сухие",
    xe: "4 столовых ложки"
  },
  {
    name: "Соевые порошок",
    xe: "2 столовых ложки"
  },
  {
    name: "Горох (свежий и консервированный)",
    xe: "4 ст. ложки с горкой"
  },
  {
    name: "Мед",
    xe: "1 столовая ложка"
  },

  {
    name: "Мед диабетический",
    xe: "1 столовая ложка"
  },
  {
    name: "Порошок для шоколадного пудинга",
    xe: "1 ст. ложка"
  },
  {
    name: "Диабетический шоколад",
    xe: "1/3 плитки"
  },
  {
    name: "Продукт",
    xe: "Количество продукта в 1 ХЕ"
  },
  {
    name: "Хурма",
    xe: "1 средний фрукт"
  },
  {
    name: " Вареники ",
    xe: "2 штуки"
  },
  {
    name: "Пирожок с мясом",
    xe: "пол пирожка"
  },
  {
    name: "Пельмени",
    xe: "4 штуки"
  },
  {
    name: "Продукт",
    xe: "Количество продукта в 1 ХЕ"
  },
  {
    name: "Квас",
    xe: "1 стакан"
  },
  {
    name: "Кока-кола, песи-кола",
    xe: "пол стакана"
  },
  {
    name: "сок капустный",
    xe: "2,5 стакана"
  },
  {
    name: "сок морковный",
    xe: "2/3 стакана"
  },
  {
    name: "сок огуречный",
    xe: "2,5 стакана"
  },
  {
    name: "сок свекольный",
    xe: "2/3 стакана"
  },
  {
    name: "сок томатный",
    xe: "1,5 стакана"
  },
  {
    name: "сок апельсиновый",
    xe: "0,5 стакана"
  },
  {
    name: "сок виноградный",
    xe: "0,3 стакана"
  },
  {
    name: "сок вишневый",
    xe: "0,4 стакана"
  },
  {
    name: "сок грушевый",
    xe: "0,5 стакана"
  },
  {
    name: "сок грейпфрутовый",
    xe: "1,4 стакана"
  },
  {
    name: "сок красносмородиновый",
    xe: "0,4 стакана"
  },
  {
    name: "сок крыжовниковый",
    xe: "0,5 стакана"
  },
  {
    name: "сок клубничный",
    xe: "0,7 стакана"
  },
  {
    name: "сок малиновый",
    xe: "0,75 стакана"
  },
  {
    name: "сок сливовый",
    xe: "0,35 стакана"
  },
  {
    name: "сок яблочный",
    xe: "0,5 стакана"
  },
  {
    name: "Гамбургер двойной",
    xe: "3 ХЕ"
  },
  {
    name: "Биг-мак тройной",
    xe: "1 ХЕ"
  },
  {
    name: "Картофель фри маленький",
    xe: "1 ХЕ"
  },
  {
    name: "Пицца",
    xe: "6 ХЕ — 300"
  },
  {
    name: "сырник",
    xe: "1 средний"
  },
  {
    name: "вареники с творогом",
    xe: "2-4 шт"
  },

  {
    name: "Хлеб ржаной",
    xe: "1 кусочек(20)"
  },
  {
    name: "Хлеб белый, булки любые (кроме сдобных)",
    xe: "1 кусочек(20)"
  },
  {
    name: "Хлеб диабетический",
    xe: "2 кусочка"
  },
  {
    name: "Яблоко (любого цвета)",
    xe: "1 средний фрукт"
  }
];
*/