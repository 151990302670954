import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { database } from '../firebase'

import {
  setDaySensitivity,
  setEveningSensitivity,
  setMorningSensitivity,
  setSensitivity
} from '../actionCreators'

class Settings extends Component {
  constructor() {
    super()
    this.state = {
      userSettings: {}
    }
    this.userSettingsRef = null
    this.saveCurrentSettings = this.saveCurrentSettings.bind(this)
  }

  saveCurrentSettings() {
    if (this.props.currentUser) {
      this.userSettingsRef = database.ref(
        `${this.props.currentUser.uid}/user-settings`
      )
      this.userSettingsRef.set({
        daySensitivity: this.props.daySensitivity,
        eveningSensitivity: this.props.eveningSensitivity,
        morningSensitivity: this.props.morningSensitivity,
        sensitivity: this.props.sensitivity
      })
    } else {
      console.log('no loggined user!')
    }
    this.props.history.push('/')
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.currentUser) {
        this.userSettingsRef = database.ref(
          `${this.props.currentUser.uid}/user-settings`
        )
      }
    }, 1000)
  }

  render() {
    return (
      <div>
        <h3 className="content-header">Чувствительность к инсулину</h3>
        <div className="form-group">
          <label className="input-label" htmlFor="morning">
            Утром:
          </label>
          <input
            value={this.props.morningSensitivity}
            onChange={this.props._setMorningSensitivity}
            type="number"
            className="main-input form-control"
          />
        </div>
        <div className="form-group">
          <label className="input-label" htmlFor="day">
            Днём:
          </label>
          <input
            type="number"
            className="main-input form-control"
            value={this.props.daySensitivity}
            onChange={this.props._setDaySensitivity}
            id="day"
          />
        </div>
        <div className="form-group">
          <label className="input-label" htmlFor="evening">
            Вечером:
          </label>
          <input
            type="number"
            className="main-input form-control"
            id="evening"
            value={this.props.eveningSensitivity}
            onChange={this.props._setEveningSensitivity}
          />
        </div>
        <div className="form-group">
          <label className="input-label" htmlFor="sensitive">
            Чувствительность к инсулину:
          </label>
          <input
            type="number"
            className="main-input form-control"
            id="sensitive"
            value={this.props.sensitivity}
            onChange={this.props._setSensitivity}
          />
          <p className="bg-info">
            <a
              href="http://diabet-med.com/raschet-dozy-insulina/"
              rel="noopener noreferrer"
              target="_blank"
              className="main-link"
            >
              2,2 ммоль/л * 63,5 кг / 42 кг = 3,33 ммоль/л
            </a>
          </p>
        </div>
        <div className="btn-group btn-group-justified">
          {this.props.currentUser ? (
            <button
              onClick={this.saveCurrentSettings}
              className="btn main-btn add-menu-btn"
            >
              Сохранить
            </button>
          ) : (
            <Link to="/" className="btn main-btn add-menu-btn">
              OK
            </Link>
          )}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  currentUser: state.currentUser,
  daySensitivity: state.daySensitivity,
  eveningSensitivity: state.eveningSensitivity,
  morningSensitivity: state.morningSensitivity,
  sensitivity: state.sensitivity
})

const mapDispatchToProps = dispatch => ({
  _setDaySensitivity(event) {
    dispatch(setDaySensitivity(event.target.value))
  },
  _setEveningSensitivity(event) {
    dispatch(setEveningSensitivity(event.target.value))
  },
  _setMorningSensitivity(event) {
    dispatch(setMorningSensitivity(event.target.value))
  },
  _setSensitivity(event) {
    dispatch(setSensitivity(event.target.value))
  }
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Settings)
)
