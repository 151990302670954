import React, { Component } from "react";
import { connect } from "react-redux";
import { database } from "../firebase";
import { Link } from "react-router-dom";
import map from "lodash/map";
import { setUsersMenu } from "../actionCreators";

class UsersMenu extends Component {
  constructor() {
    super();
    this.state = {
      ownItemName: "",
      ownItemXe: ""
    };
    this.editOwnName = this.editOwnName.bind(this);
    this.editOwnXe = this.editOwnXe.bind(this);
    this.addOwnItem = this.addOwnItem.bind(this);
    this.usersMenuRef = null;
    this.removeOwnItem = this.removeOwnItem.bind(this);
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.usersMenuRef = database.ref(
        `${this.props.currentUser.uid}/custom-menu`
      );
      this.usersMenuRef.on("value", snapshot => {
        const usersMenu = [];
        map(snapshot.val(), (item, key) => {
          item.id = key;
          usersMenu.push(item);
          this.props._setUsersMenu(usersMenu);
        });
      });
    }
  }

  /* добавляет новую позицию меню в firebase */
  addOwnItem(event) {
    if (this.state.ownItemName && this.state.ownItemXe) {
      this.usersMenuRef.push({
        name: this.state.ownItemName,
        xe: this.state.ownItemXe
      });
      this.setState({
        ownItemName: "",
        ownItemXe: ""
      });
    } else {
      console.log("empty!");
    }
  }

  removeOwnItem(key) {
    this.usersMenuRef.child(key).remove();
  }

  /* редактирует название новой позиции в своем меню*/
  editOwnName(event) {
    this.setState({ ownItemName: event.target.value });
  }

  /* редактирует колличество ХЕ новой позиции в своем меню*/
  editOwnXe(event) {
    this.setState({ ownItemXe: event.target.value });
  }

  render() {
    return (
      <div>
        <h2 className="content-header">Свое меню</h2>
        {this.props.usersMenu && (
          <table className="table table-hover menu-table">
            <thead>
              <tr>
                <th className="table-header">Наименование</th>
                <th className="table-header">Грамм в 1&nbsp;ХЕ</th>
                <th className="table-header" />
              </tr>
            </thead>
            <tbody>
              {this.props.usersMenu.map((item, key) => {
                return (
                  <tr key={key}>
                    <td className="table-cell">{item.name}</td>
                    <td className="table-cell">{`${item.xe} грамм`}</td>
                    <td className="table-cell">
                      <button
                        onClick={() => this.removeOwnItem(item.id)}
                        className="btn close-btn"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <div className="add-own-item">
          <input
            onChange={this.editOwnName}
            value={this.state.ownItemName}
            className="main-input menu-input add-own-item__menu-input"
            placeholder="Наименование"
          />
          <input
            onChange={this.editOwnXe}
            value={this.state.ownItemXe}
            placeholder="грамм в 1 ХЕ"
            type="number"
            className="main-input menu-input add-own-item__menu-input"
          />
          <button
            onClick={this.addOwnItem}
            className="btn main-btn add-menu-btn add-own-item__add-button"
          >
            +
          </button>
        </div>
        <div className="add-menu-btns">
          <Link className="btn main-btn add-menu-btn" to="/menu">
            Назад к меню
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  usersMenu: state.usersMenu
});

const mapDispatchToProps = dispatch => ({
  _setUsersMenu(usersMenu) {
    dispatch(setUsersMenu(usersMenu));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersMenu);
