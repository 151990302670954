import { combineReducers } from 'redux'

import {
  SET_SENSINSITIVITY,
  SET_MORNING_SENSITIVITY,
  SET_DAY_SENSITIVITY,
  SET_EVENING_SENSITIVITY,
  SET_TARGET_SUGAR,
  SET_CURRENT_SUGAR,
  ADD_MENU_ITEM,
  REMOVE_MENU_ITEM,
  SET_TIME,
  SET_COEFFICIENT,
  SET_EXPOSITION,
  SET_INJECTION_PLACE,
  SET_TOTAL_XE,
  SET_CURRENT_USER,
  SET_USERS_MENU,
  GET_USER_STATISTICS
} from './actions'

const userStats = (state = [], action) => {
  if (action.type === GET_USER_STATISTICS) {
    return [...action.payload]
  }
  return state
}

const time = (state = time, action) => {
  if (action.type === SET_TIME) {
    return action.payload
  }
  return state
}

const coefficient = (state = null, action) => {
  if (action.type === SET_COEFFICIENT) {
    return action.payload
  }
  return state
}

const exposition = (state = null, action) => {
  if (action.type === SET_EXPOSITION) {
    return action.payload
  }
  return state
}

const injectionPlace = (state = '', action) => {
  if (action.type === SET_INJECTION_PLACE) {
    return action.payload
  }
  return state
}

const sensitivity = (state = 3.33, action) => {
  if (action.type === SET_SENSINSITIVITY) {
    return action.payload
  }
  return state
}

const morningSensitivity = (state = 1.5, action) => {
  if (action.type === SET_MORNING_SENSITIVITY) {
    return action.payload
  }
  return state
}

const daySensitivity = (state = 1.5, action) => {
  if (action.type === SET_DAY_SENSITIVITY) {
    return action.payload
  }
  return state
}

const eveningSensitivity = (state = 1, action) => {
  if (action.type === SET_EVENING_SENSITIVITY) {
    return action.payload
  }
  return state
}

const currentSugar = (state = '', action) => {
  if (action.type === SET_CURRENT_SUGAR) {
    return action.payload
  }
  return state
}

const targetSugar = (state = '', action) => {
  if (action.type === SET_TARGET_SUGAR) {
    return action.payload
  }
  return state
}

const currentMenu = (state = [], action) => {
  if (action.type === ADD_MENU_ITEM) {
    return [...state, action.payload]
  }
  if (action.type === REMOVE_MENU_ITEM) {
    return state.filter((item, i) => item.id !== action.payload)
  }
  return state
}

const totalXe = (state = 0, action) => {
  if (action.type === SET_TOTAL_XE) {
    return action.payload
  }
  return state
}

const currentUser = (state = null, action) => {
  if (action.type === SET_CURRENT_USER) {
    return action.payload
  }
  return state
}

const usersMenu = (state = [], action) => {
  if (action.type === SET_USERS_MENU) {
    return action.payload
  }
  return state
}

const rootReducer = combineReducers({
  time,
  coefficient,
  exposition,
  injectionPlace,
  sensitivity,
  morningSensitivity,
  daySensitivity,
  eveningSensitivity,
  currentSugar,
  targetSugar,
  currentMenu,
  totalXe,
  currentUser,
  usersMenu,
  userStats
})

export default rootReducer
