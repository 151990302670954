import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { xeTable } from "../data";
import SelectMenuItem from "./SelectMenuItem";
import { addMenuItem, setTotalXe } from "../actionCreators";

class AddMenuItem extends Component {
  constructor() {
    super();
    this.state = {
      editModeIsOn: false,
      itemName: "",
      itemWeight: "",
      itemXe: "",
      itemXeV: null,
      matchArray: [],
      ownItemName: "",
      ownItemXe: "",
      customMenu: null
    };
    this.dataRef = null;
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.editName = this.editName.bind(this);
    this.editWeight = this.editWeight.bind(this);
    this.editXe = this.editXe.bind(this);
    this.addNewItem = this.addNewItem.bind(this);
    this.findMatches = this.findMatches.bind(this);
    this.selectFromDropdown = this.selectFromDropdown.bind(this);
    this.chooseMenu = [];
  }

  componentDidMount() {
    this.chooseMenu = [...xeTable, ...this.props.usersMenu];
  }

  /* открывает панель добавления позиции в меню */
  toggleEditMode() {
    const newEditMode = !this.state.editModeIsOn;
    this.setState({ editModeIsOn: newEditMode });
  }

  /* редактирует имя новой позиции в меню */
  editName(event) {
    this.findMatches(event.target.value, this.chooseMenu);
    this.setState({ itemName: event.target.value });
  }

  /* редактирует вес новой позиции в меню */
  editWeight(event) {
    const itemWeight = parseInt(event.target.value, 10);
    this.setState({ itemWeight });
  }

  /* редактирует колличество ХЕ новой позиции в меню */
  editXe(event) {
    const itemXe = parseFloat(event.target.value);
    this.setState({ itemXe });
  }

  /* выбор позиции меню из выпадающего списка */
  selectFromDropdown(itemName, itemXe) {
    this.setState({
      itemName,
      itemXe,
      matchArray: []
    });
  }

  /* генерирует случайное число */
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  /* ищет по массиву слова */
  findMatches(wordToMatch, itemsArray) {
    const matchArray = itemsArray.filter(item => {
      const regex = new RegExp(wordToMatch, "gi");
      return item.name.match(regex);
    });
    this.setState({ matchArray });
  }

  addNewItem() {
    const id = `id_${this.getRandomInt(1000000, 9999999)}`;
    const item = {
      id,
      itemName: this.state.itemName,
      itemWeight: this.state.itemWeight,
      itemXe: this.state.itemXe,
      itemXeV: this.state.itemXeV,
      itemXeTotal: parseFloat(
        (this.state.itemWeight / this.state.itemXe).toFixed(2)
      )
    };
    this.props._addMenuItem(item);
    this.setState({
      itemName: "",
      itemXe: null,
      itemWeight: null
    });
    this.toggleEditMode();
  }

  render() {
    return (
      <div className="">
        {this.state.editModeIsOn ? (
          <div>
            <div className="add-menu-item__wrapper">
              <input
                onChange={this.editName}
                value={this.state.itemName}
                className="main-input menu-input menu-input__add-item"
                placeholder="Наименование"
              />
              <input
                onChange={this.editWeight}
                placeholder="вес (грамм)"
                type="number"
                min="1"
                max="999"
                className="main-input menu-input menu-input__add-item"
              />
              <input
                onChange={this.editXe}
                value={this.state.itemXe}
                min="1"
                max="999"
                placeholder="1 ХЕ"
                type="number"
                className="main-input menu-input menu-input__add-item"
              />
              <button
                className="btn main-btn add-menu-btn"
                onClick={this.addNewItem}
              >
                +
              </button>
              <SelectMenuItem
                selectFromDropdown={this.selectFromDropdown}
                itemName={this.state.itemName}
                menuItems={this.state.matchArray}
                getId={this.getRandomInt}
              />
            </div>
            <div className="add-menu-btns">
              <button
                className="btn main-btn add-menu-btn"
                onClick={this.toggleEditMode}
              >
                Отмена
              </button>
            </div>
          </div>
        ) : (
          <div className="add-menu-btns">
            <button
              className="btn main-btn add-menu-btn"
              onClick={this.toggleEditMode}
            >
              + Добавить
            </button>
            <Link to="/results" className="btn main-btn add-menu-btn">
              Расчитать
            </Link>
          </div>
        )}
        {this.props.currentUser && (
          <div className="add-menu-btns">
            <Link to="/users-menu" className="btn main-btn add-menu-btn">
              Дополнить выбор
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentMenu: state.currentMenu,
  currentUser: state.currentUser,
  usersMenu: state.usersMenu
});

const mapDispatchToProps = dispatch => ({
  _addMenuItem(item) {
    if (item.itemXe && item.itemName && item.itemWeight) {
      dispatch(addMenuItem(item));
    }
  },
  _setTotalXe(value) {
    dispatch(setTotalXe(value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMenuItem);
